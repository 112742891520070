<template>
  <div class="page-about">
    <div class="columns is-multiline content">
      <div class="column is-full">
        <div class="title is-3 has-text-blue-dark">Read our best tips</div>
        <div class="has-text-weight-bold">To give you focus, we’ve selected the 10 best tips for utilizing your S2M passport to its full potential.</div>
      </div>

      <div class="column is-full">
        <div class="columns is-mobile">
          <div class="column-content column is-two-thirds">
            <div class="row-tip has-margin-bottom-x2">
              <span class="wrapper-tip-icon">
                <span class="tip-number">1</span>
                <i class="font-icon s2m-icon-s2m-silhouette"></i>
              </span>
              <div>
                <div class="title is-3 has-text-blue-dark">Update your bio</div>
                <div>
                  Go to
                  <router-link :to="{ name: 'Detail'}">My profile</router-link>, click Edit and fill in your biography. Show other people what your expertise is, what they can ask you, or hire you for.
                </div>
              </div>
            </div>

            <div class="row-tip">
              <span class="wrapper-tip-icon">
                <span class="tip-number">2</span>
                <i class="font-icon s2m-icon-s2m-silhouette"></i>
              </span>
              <div>
                <div class="title is-3 has-text-blue-dark">Add relevant tags</div>
                <div>
                  Add the right tags to
                  <router-link :to="{ name: 'Detail'}">your bio</router-link> to show your interests and knowledge. Our AI algorithm uses these tags to match you with other professionals, events, and other content.
                </div>
              </div>
            </div>
          </div>
          <div class="column-image column is-one-third">
            <img
              src="@/assets/img/showyourself.png"
              alt="Show yourself"
            />
          </div>
        </div>
      </div>

      <div class="column-content column is-two-thirds">
        <div class="row-tip">
          <span class="wrapper-tip-icon">
            <span class="tip-number">3</span>
            <i class="font-icon s2m-icon-s2m-silhouette"></i>
          </span>
          <div>
            <div class="title is-3 has-text-blue-dark">Share what you are doing</div>
            <div>
              <router-link :to="{ name: 'Dashboard'}">Fill in the focus field</router-link> and share it with people in the
              <router-link :to="{ name: 'Network'}">S2M network</router-link>. Our AI algorithm matches you with
              <router-link :to="{ name: 'Network'}">relevant people</router-link>,
              <router-link :to="{ name: 'Locations'}">locations</router-link>,
              <router-link :to="{ name: 'Events'}">events</router-link>, and other relevant content, and … they can match with you!
            </div>
          </div>
        </div>
      </div>

      <div class="column-content column is-two-thirds">
        <div class="row-tip">
          <span class="wrapper-tip-icon">
            <span class="tip-number">4</span>
            <i class="font-icon s2m-icon-s2m-silhouette"></i>
          </span>
          <div>
            <div class="title is-3 has-text-blue-dark">Check-in</div>
            <div>
              Let other people know where you are working today. You can check-in at a
              <router-link :to="{ name: 'Locations'}">location</router-link> but also
              <router-link :to="{ name: 'Network', query: { v: 'map' }}">virtually</router-link>. Make sure you are visible!
            </div>
          </div>
        </div>
      </div>

      <div class="column is-full">
        <div class="columns is-mobile">
          <div class="column-content column is-two-thirds">
            <div class="row-tip has-margin-bottom-x2">
              <span class="wrapper-tip-icon">
                <span class="tip-number">5</span>
                <i class="font-icon s2m-icon-s2m-silhouette"></i>
              </span>
              <div>
                <div class="title is-3 has-text-blue-dark">Ask a question</div>
                <div>
                  Need some help from an expert?
                  <router-link :to="{ name: 'QuestionsNew'}">Ask your question</router-link> and get an answer from our valuable network.
                  Or <router-link :to="{ name: 'Questions'}">explore questions</router-link> and answers from others. Of course, you can give some help back and be part of the network by answering <router-link :to="{ name: 'Questions'}">questions</router-link>.
                </div>
              </div>
            </div>
            <div class="row-tip">
              <span class="wrapper-tip-icon">
                <span class="tip-number">6</span>
                <i class="font-icon s2m-icon-s2m-silhouette"></i>
              </span>
              <div>
                <div class="title is-3 has-text-blue-dark">Create an event</div>
                <div>Do you want to share your knowledge, give a workshop, come together with some people to brainstorm or share ideas? <router-link :to="{ name: 'NewEvents'}">Create an event</router-link> (free or paid), and we will <router-link :to="{ name: 'Events'}">publish it on our website</router-link> and share it with the whole S2M network.</div>
              </div>
            </div>
          </div>
          <div class="column-image column is-one-third">
            <img
              src="@/assets/img/askyourquestion.png"
              alt="Ask your question"
            />
          </div>
        </div>
      </div>

      <div class="column-content column is-two-thirds">
        <div class="row-tip">
          <span class="wrapper-tip-icon">
            <span class="tip-number">7</span>
            <i class="font-icon s2m-icon-s2m-silhouette"></i>
          </span>
          <div>
            <div class="title is-3 has-text-blue-dark">Write a blog</div>
            <div>Show your expertise and enthusiasm by <a
                href="https://magazine.seats2meet.com/guide-for-bloggers/"
                target="_blank"
              >writing a blog <span class="icon is-small">
                  <i class="fas fa-external-link-alt"></i>
                </span></a>. Read our guide for bloggers and publish your content in <a
                href="https://magazine.seats2meet.com"
                target="_blank"
              >S2M Stories <span class="icon is-small">
                  <i class="fas fa-external-link-alt"></i>
                </span></a>. Make sure your article is informative and beneficial for the S2M network.</div>
          </div>
        </div>
      </div>

      <div class="column is-full">
        <div class="columns is-mobile">
          <div class="column-content column is-two-thirds">
            <div class="row-tip has-margin-bottom-x2">
              <span class="wrapper-tip-icon">
                <span class="tip-number">8</span>
                <i class="font-icon s2m-icon-s2m-silhouette"></i>
              </span>
              <div>
                <div class="title is-3 has-text-blue-dark">Go find some serendipity</div>
                <div>The S2M network offers you great ways to explore serendipity every day! Browse through the <router-link :to="{ name: 'Network'}">fields of expertise</router-link> and discover interesting <router-link :to="{ name: 'Network'}">people</router-link>, chat with people you’ve never met, or <router-link :to="{ name: 'Locations'}">explore a location</router-link> you’ve never been to. Be open and let the network surprise you!</div>
              </div>
            </div>
            <div class="row-tip">
              <span class="wrapper-tip-icon">
                <span class="tip-number">9</span>
                <i class="font-icon s2m-icon-s2m-silhouette"></i>
              </span>
              <div>
                <div class="title is-3 has-text-blue-dark">Look around you!</div>
                <div>Look up from your laptop and look around you. Who do you see you can offer coffee or ask a question? Seeing nobody? Dive into <router-link :to="{ name: 'Dashboard'}">your S2M passport</router-link> and find some people to connect and chat with.</div>
              </div>
            </div>
          </div>
          <div class="column-image column is-one-third">
            <img
              class="image-findCoolPlace"
              src="@/assets/img/findcoolplaces.png"
              alt="Find cool places"
            />
          </div>
        </div>
      </div>

      <div class="column is-full">
        <div class="columns is-mobile">
          <div class="column-content-tip10 column-content column is-two-thirds">
            <div class="row-tip">
              <span class="wrapper-tip-icon">
                <span class="tip-number">10</span>
                <i class="font-icon s2m-icon-s2m-silhouette"></i>
              </span>
              <div>
                <div class="title is-3 has-text-blue-dark">Use your S2M passport every day</div>
                <div>Make it a good habit to use <router-link :to="{ name: 'Dashboard'}">your S2M passport</router-link> daily. Explore, find, meet, chat, drink coffee, learn, teach, read, wonder, be open, and have fun. You want to connect every day with other people to collaborate and grow. As a professional, an individual, and most of all, together with others.</div>
              </div>
            </div>
          </div>
          <div class="column-image column is-one-third">
            <img
              src="@/assets/img/getoutofyourbubble.png"
              alt="Get out of your bubble"
            />
          </div>
        </div>
      </div>

      <div class="column-best-version column is-full has-text-centered">
        <i class="font-icon s2m-icon-best-version has-text-primary"></i>
        <div class="is-size-4 has-text-weight-bold has-text-primary">Become the best version of yourself</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn'
    })
  }
}
</script>

<style lang="scss" scoped>
.column-image {
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    display: none;
  }

  img {
    width: 60%;
    &.image-findCoolPlace {
      width: 50%;
    }
  }
}

.column-content {
  &:not(.column-content-tip10) {
    align-self: center;
  }
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
}

.row-tip {
  display: flex;
  .wrapper-tip-icon {
    $iconSize: 48px;
    height: $iconSize;
    width: $iconSize;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    margin-right: $gap * 2;
    position: relative;
    .tip-number {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: $iconSize;
      width: $iconSize;
      font-size: 24px;
      color: white;
      font-weight: $weight-bold;
    }
    .font-icon {
      display: inline-block;
      font-size: $iconSize;
      height: $iconSize;
      width: $iconSize;
      line-height: 1;
      color: $blue-dark;
      &:before {
        line-height: 1;
      }
    }
  }
}

.column-best-version {
  .font-icon {
    $iconSize: 96px;
    display: inline-block;
    height: $iconSize;
    width: $iconSize;
    font-size: $iconSize;
    line-height: 1;
    &:before {
      line-height: 1;
    }
  }
}
</style>
