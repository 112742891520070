<template>
  <div class="page-about">
    <div class="columns is-multiline content">
      <div class="column is-full">
        <div class="columns is-multiline">
          <div class="column is-two-thirds">
            <div class="columns is-multiline">
              <div class="column is-full">
                <div class="title is-3 has-text-blue-dark">Learn about the S2M passport</div>
                <div>This is the Seats2meet passport, your entrance to a large network of professionals. Our AI algorithm matches you with <router-link :to="{ name: 'Network'}">experts</router-link>, <router-link :to="{ name: 'Locations'}">locations</router-link>, <router-link :to="{ name: 'Events'}">events</router-link>, and other relevant content.<br />
                  This is the place to get answers to <router-link :to="{ name: 'QuestionsAsked'}">your questions</router-link>, <router-link :to="{ name: 'Network'}">connect and chat with people</router-link> who match your interests and work, <router-link :to="{ name: 'NewEvents'}">create your own events</router-link> and <a
                    href="https://magazine.seats2meet.com/guide-for-bloggers/"
                    target="_blank"
                  >share your knowledge <span class="icon is-small">
                      <i class="fas fa-external-link-alt"></i>
                    </span></a>. All in one place.</div>
              </div>
              <div class="column is-full">
                <div class="title is-3 has-text-blue-dark">The S2M passport: connect, collaborate and grow.</div>
                <div class="wrapper-usps">
                  <div>
                    <span class="icon is-medium has-text-success has-margin-right">
                      <font-awesome-icon
                        :icon="['fas', 'check']"
                        size="2x"
                      />
                    </span>
                    <span class="has-text-weight-bold">Connected to a global network of 130K professionals.</span>
                  </div>
                  <div>
                    <span class="icon is-medium has-text-success has-margin-right">
                      <font-awesome-icon
                        :icon="['fas', 'check']"
                        size="2x"
                      />
                    </span>
                    <span class="has-text-weight-bold">The AI-driven algorithm that connects you to other professionals, locations, events, and relevant content.</span>
                  </div>
                  <div>
                    <span class="icon is-medium has-text-success has-margin-right">
                      <font-awesome-icon
                        :icon="['fas', 'check']"
                        size="2x"
                      />
                    </span>
                    <span class="has-text-weight-bold">Accessible 24/7. On any device.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third is-hidden-mobile">
            <figure class="image">
              <img
                src="@/assets/img/Get_AI_driven_opportunities.png"
                alt="Get AI driven opportunities"
              />
            </figure>
          </div>
        </div>
      </div>
      <div class="column is-full">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="title is-3 has-text-blue-dark">See for yourself how the passport works in practice:</div>
            <div class="box box-video has-background-white">
              <iframe
                src="https://www.youtube-nocookie.com/embed/4wFQdb126tE"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-full">
        <div class="title is-3 has-text-blue-dark">Some facts & figures</div>
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="wrapper-usps has-margin-bottom">
              <div>
                <span class="icon is-medium has-text-success has-margin-right">
                  <font-awesome-icon
                    :icon="['fas', 'check']"
                    size="2x"
                  />
                </span>
                <span class="has-text-weight-bold">Connected to a global network of 130K professionals.</span>
              </div>
              <div>
                <span class="icon is-medium has-text-success has-margin-right">
                  <font-awesome-icon
                    :icon="['fas', 'check']"
                    size="2x"
                  />
                </span>
                <span class="has-text-weight-bold">More than 200 locations in 30 countries.</span>
              </div>
              <div>
                <span class="icon is-medium has-text-success has-margin-right">
                  <font-awesome-icon
                    :icon="['fas', 'check']"
                    size="2x"
                  />
                </span>
                <span class="has-text-weight-bold">The Seats2meet magazine has 400 authors and 30.000 readers.</span>
              </div>
              <div>
                <span class="icon is-medium has-text-success has-margin-right">
                  <font-awesome-icon
                    :icon="['fas', 'check']"
                    size="2x"
                  />
                </span>
                <span class="has-text-weight-bold">More than 100.000 active Passport holders in more than 32.000 fields of expertise.</span>
              </div>
              <div>
                <span class="icon is-medium has-text-success has-margin-right">
                  <font-awesome-icon
                    :icon="['fas', 'check']"
                    size="2x"
                  />
                </span>
                <span class="has-text-weight-bold">In the Passport, every question gets 3 relevant answers.</span>
              </div>
              <div>
                <span class="icon is-medium has-text-success has-margin-right">
                  <font-awesome-icon
                    :icon="['fas', 'check']"
                    size="2x"
                  />
                </span>
                <span class="has-text-weight-bold">More than 2500 events organized.</span>
              </div>
            </div>
          </div>
          <div class="column-facts-image column is-half">
            <img
              src="@/assets/img/experienceserendipity.png"
              alt="Experience serendipity"
            />
          </div>
        </div>
      </div>

      <div class="column is-full has-text-centered">
        <div class="has-text-weight-bold has-margin-bottom-x2">Also, <router-link :to="{ name: 'AboutTips'}">read our 10 best tips</router-link> to get the most out of your S2M passport!</div>
        <i class="font-icon s2m-icon-best-version has-text-primary"></i>
        <div class="is-size-4 has-text-weight-bold has-text-primary">Become the best version of yourself</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'getIsLoggedIn'
    })
  }
}
</script>

<style lang="scss" scoped>
.box-video {
  display: flex;
  align-items: center;
  justify-items: center;
  iframe {
    width: 100% !important;
    height: 100%;
    min-height: 305px;
    @media screen and (max-width: 1023px) {
      min-height: 244px;
    }
    @media screen and (max-width: 920px) {
      min-height: 210px;
    }
    @media screen and (max-width: 768px) {
      min-height: 320px;
    }
    @media screen and (max-width: 530px) {
      min-height: 280px;
    }
  }
}

.wrapper-usps {
  div {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: $gap;
    }
    span.icon {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

.column-facts-image {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 70%;
  }
}

.font-icon {
  $iconSize: 96px;
  display: inline-block;
  height: $iconSize;
  width: $iconSize;
  font-size: $iconSize;
  line-height: 1;
  &:before {
    line-height: 1;
  }
}
</style>
